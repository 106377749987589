import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';

//Fix new images later on
import distanceDiagnosis from "../img/ICONS_DistanceDiagnos.png";
import homeMonitoring from "../img/ICONS_Home.png";
import screening from "../img/ICONS_Screening.png";
import bodykomImg from "../img/bodykom.png";
import iconCTA from "../img/ICONS_CTA.png";
import storyPhoto from "../img/bjorlin_photo.png";
import iconECG from "../img/ecg_icon.png";
//import styles from '../components/base.css'

class bodykom extends React.Component{
    render(){
        const siteTitle = ('Kiwok - BodyKom')

        return (
            <Layout location={this.props.location} >
            <div style={{ background: '#fff' }}>
              <Helmet title={siteTitle} />
              
              <div className="wrapperPageServices" style={{textAlign: "center"}}>
                <h2>BodyKom</h2>
                
              <div className="wrapper1">
                <div className="wrapper-child">
                  <img src={bodykomImg} alt="Client"></img>
                </div>
                <div className="wrapper-child">
                  <h3 className="client-name">HOW DO YOU GET A CLEAR OVERVIEW OF YOUR PATIENTS' HEARTS</h3>
                  <p className="client-jobtitle">
                  
                  </p>
                  <p>
                  THE DEVICE THAT CAN MEASURE THE CONDITION OF YOUR
                  PATIENT'S HEART IN THEIR NORMAL DAY LIFE AND
                  CONTINUOUSLY SEND REAL TIME DATA TO YOU EVEN IF THEY
                  ARE NOT IN THE HOSPITAL
                  </p>
                </div>
              </div>

              <h2 className="applicationHeader">WE DO A FEW THINGS A LOT DIFFERENT</h2>
              <div className="wrapper1">
                <div className="wrapper-child">
                  <img
                    className="about-img"
                    src={distanceDiagnosis}
                    alt="Bodykom"
                  ></img>
                  <p className="boldedStart">MOBILITY</p>
                  <p>Kiwok BodyKom was the first mobile ECG solutions in the world. The mobile connection gives you the freedom to download data continuously in real time, which increases care quality, efficiency and reduces costs.</p>
                </div>
                <div className="wrapper-child">
                  <img
                    className="about-img"
                    src={homeMonitoring}
                    alt="Bodykom"
                  ></img>
                  <p className="boldedStart">UP TO 72 HRS</p>
                  <p>
                  The possibility to send continuous data for several days give a more accurate analysis and allows the caregiver to find heart-related problems faster.
                  </p>
                </div>
                <div className="wrapper-child">
                  <img
                    className="about-img"
                    src={screening}
                    alt="Bodykom"
                  ></img>
                  <p className="boldedStart">COMPACT DESIGN</p>
                  <p>
                  For the patient it’s of high value to be able to live a normal life while the measurements take place. And that no obvious signs of measurement equipment is to be seen.
                  </p>
                </div>
              </div>
              <div className="greyBackground">
              <div className="wrapperPage">
                <h2>IS THIS FOR YOU</h2>
                <div className="CTAImage">
                <img className="about-img"
                      src={iconCTA}
                      alt="Bodykom"
                    ></img>
                    </div>
                    <p>Are you as cardiologist or analyst often worried over the fact that you do not have updated information regarding your patients heart condition? Would you appreciate handling your heart patients when on a distance and getting continues ECG data transmitted in real time and alerts from the decision management system when your attention is needed? Would your mission as a clinician be improved by the fact that you can monitor the patient´s heart in real time even if the patient is not in the ward and would an alert, when need arises, improve the quality of your care? Would the care output of your clinic increase if patients that do not need immediate care, even if they need continues monitoring, not need to visit the clinic? Do you need to be able to screen a large group of heart patients for longer time? Would you consider using a proven ECG solution with improved digital thecnology as a change?
                    If your answer to one or two of the questions above is yes, please contact us to find out more how we could improve your every day as a clinician!</p>
                    </div>
              </div>
              <div className="wrapperPage">
                <h2>OUR ADVANCED ANALYSIS SERVICE</h2>
                <div className="CTAImage">
                <img className="about-img"
                      src={iconECG}
                      alt="Bodykom"
                    ></img>
                    </div>
                    <h4>DISCOVER THE BENEFITS OF AN ADVANCED ANALYSE SERVICE OF LONG TERM ECG MEASUREMENTS WITH MOBILE EQUIPMENT ON LOAN.</h4>
                    <p>Now you can purchase an analysis for up to a three-day continuous ECG measurement with mobile
                    equipment on loan from Kiwok. Get in touch with us to receive further details.</p>
                    </div>
                    <h2 className="applicationHeader">THE BIGGER PICTURE</h2>
              <div className="wrapper1">
                <div className="wrapper-child">
                  <img
                    className="about-img"
                    src={distanceDiagnosis}
                    alt="Bodykom"
                  ></img>
                  <p className="boldedStart">SPACE</p>
                  <p>If your ward is filled, BodyKom will give you space</p>
                </div>
                <div className="wrapper-child">
                  <img
                    className="about-img"
                    src={homeMonitoring}
                    alt="Bodykom"
                  ></img>
                  <p className="boldedStart">FUNDING</p>
                  <p>If your budget is strained, BodyKom will lead to funding</p>
                </div>
                <div className="wrapper-child">
                  <img
                    className="about-img"
                    src={screening}
                    alt="Bodykom"
                  ></img>
                  <p className="boldedStart">RESULT</p>
                  <p>If your care process is slow, BodyKom will give you instant result</p>
                </div>
              </div>
              <h2>OUR AMAZING PRODUCT: THIS IS BODYKOM</h2>
              <div className="wrapper1">
                <div className="wrapper-child">
                  <img src={bodykomImg} alt="Client"></img>
                </div>
                <div className="wrapper-child">
                <tbody className="bodykomSpecs" style={{textAlign: "left"}}>
                <tr>
                <td>Developer</td>
                <td><span style={{color: "#f37022"}}><strong>Kiwok/SR</strong></span></td>
                </tr>
                <tr>
                <td>Waterproofing</td>
                <td><strong>Yes</strong></td>
                </tr>
                <tr>
                <td>Batterylife</td>
                <td><strong>Up to 7 days</strong></td>
                </tr>
                <tr>
                <td>Weight</td>
                <td><strong>42 grams</strong></td>
                </tr>
                <tr>
                <td>Powersource</td>
                <td><strong>Built-in rechargable</strong></td>
                </tr>
                <tr>
                <td>No. Channels</td>
                <td><strong>1-12</strong></td>
                </tr>
                <tr>
                <td>Recording period</td>
                <td><strong>Up to 7 days</strong></td>
                </tr>
                <tr>
                <td>Storage capacity</td>
                <td><strong>32GB</strong></td>
                </tr>
                <tr>
                <td>Patient monitoring</td>
                <td><strong>Yes</strong></td>
                </tr>
                <tr>
                <td>OS compatibility</td>
                <td><strong>iOS + Andriod</strong></td>
                </tr>
                </tbody>
                </div>
              </div>
              <div className="greyBackground">
              <div className="wrapperPage" style={{minHeight: "0"}}>
                <h2>WHY KIWOK</h2>  
                    <p>We have introduced the use of mobile ECG monitoring and that in combination with wellness
                      coaching and healthcare advice will most likely lead to a better public health and to reduced
                      cost of care, we believe in preventive actions instead of just caring for the already sick.
                      Kiwok, founded in 2003, is a Swedish based MedTech company that has developed one of the
                      first mobile connected Remote Patient Monitoring service for ECG. The company has method 
                      patents for the solution in the US and South Korea. Kiwok is seeking clinics for reference 
                      deliveries, wellness oriented institutions to test our products as well as partners for 
                      international development projects. We are also seeking agents for global distribution.</p>
                    </div>
                    </div>
              <div id="contact" className="wrapperPage" style={{minHeight: "0"}}>
                <h2>GET IN TOUCH WITH US</h2>
                    <p>KIWOK DEVELOPMENT</p>
                    <p>INFO@KIWOK.SE</p>
                    <p>+46 (0)8 550 036 70</p>
                    <p><a href="https://www.linkedin.com/company/kiwok-nordic-ab-publ/about/">LINKEDIN</a></p>
                    <p><a href="https://www.facebook.com/kiwokswe/">FACEBOOK</a></p>
                    <p><a href="https://www.youtube.com/channel/UCs5ai8zfAmmSOf7x8arorqg">YOUTUBE</a></p>
                    </div>
              </div>
              </div>
          </Layout>
        )
    }
}

export default bodykom
